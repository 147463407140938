<template>
  <div class="container-fluid dashboard">
    <div class="row">
      <div class="col-6 col-lg-4 linkContainer">
        <router-link to="/profile">
          <div class="page-link">
            <div class="imgContainer">
              <img src="../assets/img/dashboard/Component 2 – 1.png" alt="" />
            </div>
            <p>Profile</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-lg-4 linkContainer">
        <router-link to="/vaccine-history">
          <div class="page-link">
            <div class="imgContainer">
              <img
                src="../assets/img/dashboard/002---Donor-Card-1.png"
                alt=""
              />
            </div>
            <p>My Vaccine History</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-lg-4 linkContainer">
        <router-link to="/vaccine-card">
          <div class="page-link">
            <div class="imgContainer">
              <img src="../assets/img/dashboard/002---Donor-Card.png" alt="" />
            </div>
            <p>My Vaccine Card</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-lg-4 linkContainer">
        <router-link to="/qr-code">
          <div class="page-link">
            <div class="imgContainer">
              <img src="../assets/img/dashboard/qr-code.png" alt="" />
            </div>
            <p>My QR Code</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-lg-4 linkContainer">
        <router-link to="/health-information">
          <div class="page-link">
            <div class="imgContainer">
              <img src="../assets/img/dashboard/Information.png" alt="" />
            </div>
            <p>Health Information</p>
          </div>
        </router-link>
      </div>
      <div class="col-6 col-lg-4 linkContainer">
        <router-link to="/">
          <div class="page-link">
            <div class="imgContainer">
              <img src="../assets/img/dashboard/travel.png" alt="" />
            </div>
            <p>Travel Information</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    var storeData = this.$store.getters.getData;
    console.log(storeData);
  },
  created() {
    // if (this.$store.getters.getAuth == false) {
    //   this.$router.push("/login");
    // }
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  height: 100%;
  min-height: calc(100vh - 145px);
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .row {
    .linkContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 20px;

      @media (min-width: 1250px) {
        padding: 15px;
      }
      @media (max-width: 768px) {
        justify-content: center;
      }
      @media (max-width: 450px) {
        padding: 10px;
      }

      a {
        width: 100%;
        height: 100%;

        .page-link {
          cursor: pointer;
          box-shadow: 20px 20px 50px #00000029;
          border: 1px solid #ebebeb;
          border-radius: 35px;
          padding: 20px;
          max-height: 220px;
          max-width: 230px;
          min-height: 150px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 auto;

          .imgContainer {
            height: 60%;
            margin-bottom: 20px;

            > img {
              object-fit: contain;
              max-height: 100px;
              max-width: 100%;
            }
          }

          > p {
            font-size: 1.2rem;
            color: #333333;
            text-align: center;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
</style>
